import React, { useState, useEffect } from 'react';
import { Table, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios'
import parse from 'html-react-parser';
import './App.css';

const formatNumber = (q) => {
  return q.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
  }).slice(0, -3)
 } 

const salaryRange = (salary) => {
  if (salary.length === 1){
    return `Compensation: ${formatNumber(salary[0])}`
  } else {
    return `Compensation: ${formatNumber(salary[0])} - ${formatNumber(salary[1])}`
  }
} 

function App() {
  const [ comments, setComments ] = useState([]);
  const [ title, setTitle ] = useState('')
  const [ index, setIndex ] = useState(0);

  async function loadHiring(){
    const result = await axios.get('/.netlify/functions/scrape')

    setComments(result.data.comments)
    setTitle(result.data.title)
    console.log(result)
  }

  useEffect(() => {
    loadHiring()
  }, [index])

  return (
    <div className="App">
      <div class="header">
        {!title &&
          <h1>Ask HN: Who is hiring?</h1>
        }
        <h1>{title}</h1>
        <h2>With listed 💵</h2>
      </div>
      { comments.length === 0 &&
        <div class="spinner">
          <Spinner animation="border" role="status" size='lg'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>  
        </div>
      }
      { comments &&
        <div>
        <Table striped bordered hover>
        <tbody>
          {
            comments.map((comment, key) => {
              if (comment.post) {
                return (
                <tr>
                  <td>
                    <p style={{color: '#1abc9c'}}>
                      <Badge bg="secondary" style={{backgroundColor: '#1abc9c'}}>
                        { salaryRange(comments[key].salary) }
                      </Badge>
                      
                    </p>
                    <div>{parse(comments[key].post)}</div>
                  </td>
                </tr>
                )
              }
            })
          }
        </tbody>
        </Table>
        </div>
      }
    </div>
  );
}

export default App;
